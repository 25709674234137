<template>
  <div>
    <!-- <span class='r---product-page-header'>{{ $t('product_page_brand') }}</span> -->
    <v-slide-group center-active hide-arrows class='px-4 mb-4 r---product-page-filter-group'>
      <v-slide-item v-for='brand in brand_options' :key='brand.id' class='mr-3'>
        <div :class="brand.selected ? 'r---product-page-filter-active' : 'r---product-page-filter-inactive'"
          style='border-radius: 6px;'
          class='d-flex align-center justify-center'
          height=90
          width=90
          >
          <v-img @click="on_option_select('brand', brand.id)"
            :src='brand.image_url'
            height=80
            width=80
            transition='fade'
            contain
            >
            <template v-slot:placeholder>
              <LoadingSpinner></LoadingSpinner>
            </template>
          </v-img>
        </div>
      </v-slide-item>
    </v-slide-group>

    <div v-show='relation_filters.some((filter) => filter.options.length > 1)'>
      <!-- <span class='r---product-page-header'>{{ $t('product_page_options') }}</span> -->
      <v-slide-group v-for="relation in relation_filters" :key="relation.type" v-show='relation.options.length > 1' hide-arrows class="px-4 mb-4 r---product-page-filter-group">
        <v-slide-item v-for="option in relation.options" :key="option.name" v-slot="{ active }">
          <v-btn @click='on_relation_select(relation.type, option.name)'
            :input-value='active'
            :class="option.selected ? 'r---product-page-filter-active' : 'r---product-page-filter-inactive'"
            class='mr-4 r---product-page-filter'
            depressed
          >
            {{ option.name }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>

    <div v-show='unit_options.length > 1'>
      <!-- <span class='r---product-page-header'>{{ $t('product_page_unit') }}</span> -->
      <v-slide-group class="px-4 mb-4 r---product-page-filter-group" hide-arrows>
        <v-slide-item v-for="unit in unit_options" :key="unit.id" v-slot="{ active }">
          <v-btn @click="on_option_select('unit', unit.id)"
            :input-value="active"
            :class="unit.selected ? 'r---product-page-filter-active' : 'r---product-page-filter-inactive'"
            class="mr-4 r---product-page-filter"
            depressed
          >
            {{ unit.name }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>

    <div v-show='pack_options.length > 1'>
      <!-- <span class='r---product-page-header'>{{ $t('product_page_pack') }}</span> -->
      <v-slide-group class="px-4 mb-4 r---product-page-filter-group" hide-arrows>
        <v-slide-item v-for="pack in pack_options" :key="pack.id" v-slot="{ active }">
          <v-btn @click="on_option_select('pack', pack.id)"
            :input-value="active"
            :class="pack.selected ? 'r---product-page-filter-active' : 'r---product-page-filter-inactive'"
            class="mr-4 r---product-page-filter"
            depressed
          >
            {{ pack.name }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import tool from "../lib/helpers";
import LoadingSpinner from "@/components/loading_spinner"

export default {
  name: "SellableFilter",
  components: { LoadingSpinner },
  props: {
    sellables: { type: Array },
    brands: { type: Array },
    relations: { type: Array },
    units: { type: Array },
    packs: { type: Array }
  },
  data: () => ({
    selected_brand: {},
    selected_unit: {},
    selected_pack: {}
  }),
  computed: {
    brand_options() {
      return this.$store.state.sellable_filter.brand;
    },
    relation_filters() {
      return this.$store.getters["sellable_filter/relations_with_options"];
    },
    unit_options() {
      return this.$store.state.sellable_filter.unit;
    },
    pack_options() {
      return this.$store.state.sellable_filter.pack;
    }
  },
  watch: {},
  methods: {
    on_option_select(type, id) {
      this.$store.dispatch("sellable_filter/select_option", {
        type: type,
        id: id
      });
    },
    on_relation_select(type, name) {
      this.$store.dispatch("sellable_filter/select_relation_option", {
        type: type,
        name: name
      });
    }
  },
  created() {
    this.$store.dispatch("sellable_filter/initialize_product", {
      sellables: tool.as_json(this.sellables),
      brands: tool.as_json(this.brands),
      relations: tool.as_json(this.relations),
      units: tool.as_json(this.units),
      packs: tool.as_json(this.packs)
    });
  },
  destroyed() {
    this.$store.dispatch("sellable_filter/flush");
  }
};
</script>

<style lang='scss'>
.border-active{
  border: 2px solid var(--v-primary-base);
}
</style>
