
import QuantityControl from '@/components/quantity_control'

export default {
  props: {
    offer: { type: Object }
    per_kg: { type: Boolean, default: false }
    qty: { type: Number }
  }

  components: { QuantityControl }

  methods: {
    remove_cart_offer: (offer_id) ->
      @$store.dispatch("cart/remove_item", offer_id)

    set_cart_offer_qty: (value) ->
      @$store.dispatch("cart/set_item_qty", { offer_id: @offer.id, qty: value })

    add_to_cart: ->
      qty = if @per_kg then 0.25 else 1
      @$store.dispatch("cart/add_item", { offer: @offer, qty: qty })
  }
}
